import moment from 'moment'

// Capitalizes the first character in a string
export function capitalize(text) {
    let first = text.charAt(0)
    return first.toUpperCase() + text.slice(1)
}

// Capitalize all words in a string
export function capitalizeWords(text) {
    let capped = text.replace(/ (\w)/g, (match, char) => {
        return ' ' + char.toUpperCase()
    })
    return capitalize(capped)
}

// Un-camel cases a string
// e.g. "camelCaseRules" -> "camel Case Rules"
export function unCamel(text) {
    let space_text = text.replace(/([a-z0-9])([A-Z])/g, (match, lc, uc) => {
        return [lc, uc].join(' ')
    })
    return space_text
}

// Un-snake case a string, retaining original capitalisation
// e.g. "snake_Case_Is_best" -> "snake Case Is best"
export function unSnake(text) {
    let spaced = text.replace(/_(\w)/g, (match, p1) => {
        return ' ' + p1.toUpperCase()
    })
    return capitalize(spaced)
}

// Converts a any_cased_String string into a Capitalised Sentence Case String.
// e.g. "capitalised_sentenceCaseIsBetter" -> "Capitalised Sentence Case Is Better"
export function anyCaseToCapitalisedSentenceCase(text) {
    return capitalizeWords(unCamel(unSnake(text)))
}


// Flattens a list of objects to a list of one attribute of the objects
export function flatten(list, attr) {
    let newlist = []
    for (let item of list) {
        newlist.push(item[attr])
    }
    return newlist
}

// Takes a string, returns it wrapped in a div as a HTML element
// This is needed as vis.js changed how it handles HTML in titles
// https://visjs.github.io/vis-network/examples/network/other/html-in-titles.html
export function stringToElement(my_string) {
    const container = document.createElement("div");
    container.innerHTML = my_string;
    return container;
  }

export function formatDate(time) {
    return moment(time).format('Do MMMM YYYY')
}

export function formatDateTime(time) {
    return moment(time).format('hh:mm A MMM Do, YYYY')
}

// Check whether array1 contains elements in array2
export function hasElement(array1, array2) {
    return array1.some(element => array2.indexOf(element) != -1)
}

export function sentenceTrimmer(sentence) {
    const maximumLength = 280
    let firstString
    let lastString = ""
    let indexOfSpace
    if (sentence.length <= maximumLength) { return sentence; }
    firstString = sentence.slice(0, maximumLength)
    indexOfSpace = Math.min(firstString.length, firstString.lastIndexOf(" "))

    if (sentence.length <= maximumLength * 2) {
        firstString = firstString.substr(0, indexOfSpace) + "\n"
        lastString = sentence.slice(indexOfSpace + 1, maximumLength * 2)
        return firstString + lastString
    } else {
        firstString = firstString.substr(0, indexOfSpace) + "\n"
        let secondString = sentence.slice(indexOfSpace + 1, indexOfSpace + 1 + maximumLength)
        let indexOfSpaceSecondString = Math.min(secondString.length, secondString.lastIndexOf(" "))
        secondString = secondString.slice(0, indexOfSpaceSecondString) + "…\n"
        lastString = sentence.slice(-maximumLength)
        indexOfSpace = lastString.indexOf(" ")
        lastString = "…" + lastString.slice(indexOfSpace + 1)
        return firstString + secondString + lastString
    }
}
