/* global start_tour, hlpid */

// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// mdn-polyfils for DOM API polyfills (core-js is only for js language features)
import 'mdn-polyfills/Element.prototype.closest'
// NodeList.forEach is used by django-debug-toolbar
// import 'mdn-polyfills/NodeList.prototype.forEach'
// Reinvestigate this polyfill when core-js is updated to 3
import 'url-search-params-polyfill'
import 'details-polyfill'  // This is an npm polyfill for <details> tag.
import objectFitImages from 'object-fit-images'

import 'bootstrap'
import 'eonasdan-bootstrap-datetimepicker'
import aristotleTooltip from '@aristotle-metadata-enterprises/aristotle_tooltip'
import '@aristotle-metadata-enterprises/aristotle_tooltip/dist/tooltip.css'
import initHelpHero from 'helphero';

import { initSentry} from "./sentry-setup";
import { initNotifications } from './notify.js'
import { initMessages } from './messages.js'
import { initDAL } from './dal-simple-init.js'
import { initCKEditor } from './ckeditor-simple-init.js'
import { initTime } from './local-time.js'
import { initSuggest } from './suggest.js'
import { initLogoutBanner } from './logout-banner.js'
import { AristotleSearchInitial } from 'src/components/omnisearch/components/aristotle-search-initial'
import { AristotleSearchResults } from 'src/components/omnisearch/components/aristotle-search-results'

// Always on styles
import 'src/styles/bootstrap.less'
import '@Aristotle-Metadata-Enterprises/fontawesome-pro/css/all.css'
import '@Aristotle-Metadata-Enterprises/fontawesome-pro/css/v4-shims.css'
import 'src/styles/aristotle.less'
import 'src/styles/aristotle.print.less'
import 'src/styles/aristotle.visuals.less'
import 'src/styles/bootstrap.wcag.css'
import 'src/styles/pink.wcag.css'
import 'src/styles/aristotle.autocomplete.css'
import 'src/styles/util.less'

import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'

// Initialize sentry as early as possible
initSentry()

// Register Web Component
customElements.define('aristotle-search-initial', AristotleSearchInitial)
customElements.define('aristotle-search-results', AristotleSearchResults)

export function initWidgets() {
    // Initialize popovers
    $('.aristotle-bs-popover').popover()

    // Initialize datepickers
    $('.dj-datepicker').each((index, obj) => {
        obj = $(obj)
        var options = obj.attr('options')
        if (options == undefined) {
            options = {format: 'YYYY-MM-DD'}
        } else {
            options = JSON.parse(options)
        }
        obj.datetimepicker(options)
    })

    //Initialise delete checkboxes
    $('.delete-disable').click(function() {
        var deletebox = $(this)
        var checked = deletebox.prop('checked')
        var form = deletebox.closest('form')
        form.find('input').each(function() {
            if ($(this).attr('id') != deletebox.attr('id') && $(this).attr('name') != 'csrfmiddlewaretoken') {
                $(this).prop('disabled', checked)
            }
        })
        form.find('.widget-button').each(function() {
            $(this).prop('disabled', checked)
        })
    })

    // Initialize django-autocomplete-light
    initDAL()

    // Initialize ckeditor
    initCKEditor()

    // Initialize suggest buttons
    initSuggest()
}


export function initModalScrap() {
    // Needs to be run on document ready
    $(document).ready(function() {
        // make new editor the default editor
        localStorage.setItem("openApiEditor", true)
        localStorage.setItem("oldEditorSelected", false)
        localStorage.setItem("openCreateApiEditor", true)
        // Scrap modals if they lose focus so they can be loaded with new content
        $('.modal').on('hidden.bs.modal', function()
            {
                if (!$(this).hasClass('exclude-scrap')) {
                    $(this).removeData();
                    var x = $(this).find('.modal-content > *');
                    x.remove()
                }
            });

        $('.modal').on('loaded.bs.modal', function() {
            // Need to do this on modal show for newly added popovers
            $('.dj-datepicker').datetimepicker({format: 'YYYY-MM-DD'})
            $('.aristotle-popover').popover()
        });

    })
}


export function initSearchBar() {
    // Needs to be run on document ready
    $(document).ready(function() {
        $('.category_selector li').on('click', function() {
            document.getElementById('category-button-face').textContent = this.innerText
        });
    })
}

export function initAristotleTooltips() {
    let options = {
        'url': "", // Call the API of its own registry
        'definitionWords': 50,
        'longDefinitionWords': 75
    };

     $(document).ready(function() {
         aristotleTooltip(options);
    });
}


export function initTooltips() {
     $(document).ready(function() {
        // Initialize bootstrap tooltips
        $('[data-toggle="tooltip"]').tooltip()
    });
}


export function initHelpTours() {
    if (start_tour) {
        var hlp = initHelpHero('sxyC0wOXCR');
        hlp.identify(hlpid);
    }
}


// This should be run on most pages
export function initCore() {
    objectFitImages()
    initAristotleTooltips()
    initTooltips()
    initNotifications()
    initMessages()
    initTime()
    initSearchBar()
    initLogoutBanner()
    initHelpTours()
}

// Init all function, only use if the page actually needs all this
export default function init() {
    initCore()
    initWidgets()
    initModalScrap()
}
