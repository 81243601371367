/* eslint-env node */

// Setup sentry
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import { CaptureConsole } from '@sentry/integrations'
import Vue from 'vue'

export function initSentry() {
    if (process.env.NODE_ENV == 'development') {
        // eslint-disable-next-line no-console
        console.log("IN DEVELOPMENT MODE: Sentry Vue integration not active")
    } else {
        Sentry.init({
            Vue,
            dsn: "https://414cc3a9b71f48d59d1c76a6b49e7291@o205873.ingest.sentry.io/6158143",
            integrations: [
                new Integrations.BrowserTracing(),

                // Report console.error to sentry
                new CaptureConsole({
                    levels: ['error']
                })
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Tracking vue errors raise by child components
            trackComponents: true,

            // Enabled sentry to normalize contextual data to a given depth
            normalizeDepth: 10
        })
    }
}

