function clearMessages() {
    const messageList = document.querySelector('#messages-row .alert ul')
    // Remove all elements from list
    if (messageList){
        for (let child of messageList.childNodes) {
            messageList.removeChild(child)
        }
    }
}

function setAlertHidden(state) {
    const alert = document.querySelector('#messages-row .alert')
    if(alert){
        alert.hidden = state
    }
}

// Add a new message, clearing existing ones
export function addHeaderMessage(message) {
    clearMessages()
    setAlertHidden(false)

    const messageList = document.querySelector('#messages-row .alert ul')
    if(messageList){
        const element = document.createElement('li')
        element.appendChild(document.createTextNode(message))
        messageList.appendChild(element)
    }
}

// Initialize messages functionality
export function initMessages() {
    const closeButton = document.querySelector('#messages-row .alert button.close')
    if (closeButton) {
        closeButton.addEventListener('click', () => {
            clearMessages()
            setAlertHidden(true)
        })
    }
}
