export class AristotleSearchInitial extends HTMLElement {
    static get observedAttributes() {
        return ['current_so_id']
    }

    constructor() {
        super()
        this.container = this.createContainer()
        this.appendChild(this.container)
        this.fetchData()
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            this.fetchData() // Re-fetch data to apply the current_so_id filter
        }
    }

    createContainer() {
        const container = document.createElement('div')
        container.setAttribute('class', 'search-initial-container')
        return container
    }

    async fetchData() {
        try {
            const response = await fetch('/api/internal/omnisearch/component-initial/')
            const data = await response.json()
            this.render(data)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    render(data) {
        this.container.innerHTML = ''
        const mainContainer = document.createElement('div')
        mainContainer.setAttribute('class', 'search-initial-main-container')

        const headerContainer = this.createHeader(data.stewardship_organisation)
        if (headerContainer) {
            mainContainer.appendChild(headerContainer)
        }

        const leftPart = this.createLeftPart(data.actions, data.stewardship_organisation)
        const rightPart = this.createRightPart(data.history.search_history, data.popular.metadata, data.stewardship_organisation)

        const contentContainer = document.createElement('div')
        contentContainer.setAttribute('class', 'search-initial-content-container')

        contentContainer.appendChild(leftPart)
        contentContainer.appendChild(rightPart)

        mainContainer.appendChild(contentContainer)
        this.container.appendChild(mainContainer)
    }


    createHeader(stewardshipOrganisations) {
        const currentSoId = this.getAttribute('current_so_id')
        const currentSoIdInt = currentSoId ? parseInt(currentSoId) : null
        const matchingOrganisation = stewardshipOrganisations.find(
            org => org.id === currentSoIdInt
        )

        if (matchingOrganisation) {
            const headerContainer = document.createElement('div')
            headerContainer.setAttribute('class', 'search-initial-header-container')
            headerContainer.style.display = 'flex'
            headerContainer.style.alignItems = 'center'

            const orgName = document.createElement('h3')
            orgName.textContent = matchingOrganisation.name
            orgName.style.marginRight = '8px'

            if (matchingOrganisation.profile_picture_url) {
                const profileImg = document.createElement('img')
                profileImg.src = matchingOrganisation.profile_picture_url
                profileImg.alt = `${matchingOrganisation.name} Profile Picture`
                profileImg.setAttribute('class', 'profile-picture')
                profileImg.style.width = '28px'
                profileImg.style.height = '28px'
                profileImg.style.borderRadius = '50%'
                headerContainer.appendChild(profileImg)
            }

            headerContainer.appendChild(orgName)
            return headerContainer
        }

        return null
    }

    createLeftPart(actions, stewardshipOrganisations) {
        const leftPart = document.createElement('div')
        leftPart.setAttribute('class', 'search-initial-left-part')

        const currentSoId = this.getAttribute('current_so_id')
        const currentSoIdInt = currentSoId ? parseInt(currentSoId) : null

        const actionKeys = Object.keys(actions)

        actionKeys.forEach(action => {
            const actionSection = document.createElement('div')
            actionSection.classList.add('aristotle-search-initial-action-section')
            const sectionHeader = document.createElement('h4')
            sectionHeader.classList.add('aristotle-search-initial-header')

            sectionHeader.textContent = action.replace(/_/g, ' ').replace(/^./, str => str.toUpperCase())
            if (action === 'recent_issues') {
                sectionHeader.setAttribute('title', 'Most recently created issues')
            } else if (action === 'recent_reviews') {
                sectionHeader.setAttribute('title', 'Most recently created reviews')
            }
            actionSection.appendChild(sectionHeader)

            const actionList = document.createElement('div')
            actionList.setAttribute('class', 'search-initial-action-list')

            const filteredItems = actions[action].filter(item =>
                currentSoIdInt ? item.so_id === currentSoIdInt : true
            )

            if (filteredItems.length > 0) {
                filteredItems.forEach(item => {
                    const itemLink = document.createElement('a')
                    itemLink.setAttribute('class', 'search-initial-result-item')
                    itemLink.setAttribute('href', item.url)

                    if (action === 'recently_viewed_metadata') {
                        const matchingOrg = stewardshipOrganisations.find(org => org.id === item.so_id)
                        let badgeText = item.item_type_name.split(' ').map(word => word.charAt(0).toUpperCase()).join('')
                        itemLink.innerHTML = `
                            <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                                <div style="flex: 1; display: flex; align-items: center;">
                                    ${badgeText ? `<span class="badge">${badgeText}</span> ` : ''}
                                    <img src="${matchingOrg && matchingOrg.profile_picture_url ? matchingOrg.profile_picture_url : ''}"
                                        alt="Icon"
                                        style="width: 30px; height: 30px; margin-right: 5px; ${matchingOrg && matchingOrg.profile_picture_url ? '' : 'visibility: hidden;'}">
                                </div>
                                <div style="flex: 4;">
                                    <div>${item.name}</div>
                                    <div>
                                        <small>${item.item_type_name}</small>
                                    </div>
                                </div>
                            </div>
                        `
                    } else {
                        // Default structure for other actions
                        itemLink.innerHTML = `
                            <span class="search-initial-icon-container">${item.icon || ''}</span> ${item.name}
                        `
                    }
                    actionList.appendChild(itemLink)
                })
            } else {
                const emptyMessage = document.createElement('div')
                emptyMessage.setAttribute('class', 'no-search-initial-result-item')
                emptyMessage.textContent = `No ${action.replace(/_/g, ' ')}`
                actionList.appendChild(emptyMessage)
            }
            actionSection.appendChild(actionList)
            leftPart.appendChild(actionSection)
        })

        const advancedSearchButton = this.createAdvancedSearchButton()
        const footerContainer = document.createElement('div')
        footerContainer.setAttribute('class', 'search-initial-footer')
        footerContainer.appendChild(advancedSearchButton)
        leftPart.appendChild(footerContainer)

        return leftPart
    }

    createRightPart(searchHistory, popularItems, stewardshipOrganisations) {
        const rightPart = document.createElement('div')
        rightPart.setAttribute('class', 'search-initial-right-part')

        const historyHeader = document.createElement('h4')
        historyHeader.classList.add('aristotle-search-initial-header')
        historyHeader.textContent = 'Search History'
        rightPart.appendChild(historyHeader)
        this.renderSearchHistory(searchHistory, rightPart)

        const popularHeader = document.createElement('h4')
        popularHeader.classList.add('aristotle-search-initial-header-popular')
        popularHeader.textContent = 'Popular'
        rightPart.appendChild(popularHeader)
        this.renderPopularItems(popularItems, stewardshipOrganisations, rightPart)

        return rightPart
    }

    renderSearchHistory(searchHistory, rightPart) {
        if (searchHistory.length) {
            searchHistory.forEach(item => {
                if (item.search_text) {
                    const searchItem = document.createElement('div')
                    searchItem.setAttribute('class', 'search-initial-result-item')
                    searchItem.innerHTML = `
                        <span class="icon-container">${item.icon || ''}</span> ${item.search_text}
                    `
                    searchItem.addEventListener('click', () => {
                        window.location.href = `/account/recently_searched/${item.id}`
                    })
                    rightPart.appendChild(searchItem)
                }
            })
        } else {
            const noHistoryMessage = document.createElement('div')
            noHistoryMessage.setAttribute('class', 'no-search-initial-result-item')
            noHistoryMessage.textContent = "No search history"
            rightPart.appendChild(noHistoryMessage)
        }
    }

    renderPopularItems(popularItems, stewardshipOrganisations, rightPart) {
        const currentSoId = this.getAttribute('current_so_id')
        const currentSoIdInt = currentSoId ? parseInt(currentSoId) : null

        const filteredItems = popularItems.filter(item =>
            currentSoIdInt ? item.so_id === currentSoIdInt : true
        )

        if (filteredItems.length) {
            filteredItems.forEach(item => {
                const popularItem = document.createElement('a')
                popularItem.href = item.url
                popularItem.setAttribute('class', 'search-initial-result-item')
                let badgeText = item.item_type_name.split(' ').map(word => word.charAt(0).toUpperCase()).join('')
                const matchingOrg = stewardshipOrganisations.find(org => org.id === item.so_id)
                popularItem.innerHTML = `
                    <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                        <div style="flex: 1; display: flex; align-items: center;">
                            ${badgeText ? `<span class="badge">${badgeText}</span> ` : ''}
                            <img src="${matchingOrg && matchingOrg.profile_picture_url ? matchingOrg.profile_picture_url : ''}"
                                alt="Icon"
                                style="width: 30px; height: 30px; margin-right: 5px; ${matchingOrg && matchingOrg.profile_picture_url ? '' : 'visibility: hidden;'}">
                        </div>
                        <div style="flex: 4;">
                            <div>${item.name}</div>
                            <div>
                                <small>${item.item_type_name}</small>
                            </div>
                        </div>
                    </div>
            `
                rightPart.appendChild(popularItem)
            })
        } else {
            const emptyMessage = document.createElement('div')
            emptyMessage.setAttribute('class', 'no-search-initial-result-item')
            emptyMessage.textContent = 'No popular items available'
            rightPart.appendChild(emptyMessage)
        }
    }

    createAdvancedSearchButton() {
        const advancedSearchButton = document.createElement('button')
        advancedSearchButton.textContent = 'Advanced Search'
        advancedSearchButton.setAttribute('class', 'search-initial-button')
        advancedSearchButton.addEventListener('click', (event) => {
            event.preventDefault()
            window.location.href = '/search/'
        })
        return advancedSearchButton
    }
}
