/* globals CKEDITOR */
import 'src/styles/ckeditor_plugins.css'
import { initDALWidget } from 'src/lib/dal-simple-init.js'
import { buildElement } from 'src/lib/html.js'

function buildGlossaryDialogHtml(item_label, select_id, dalurl) {
    // Build html content to go inside the aristotle popover dialog

    let final_label = item_label + ':'
    let div = document.createElement('div')
    let p = document.createElement('p')
    p.innerText = "Use the search box below to find and insert a link to a metadata item."

    div.appendChild(p)
    let br = document.createElement('br')
    div.appendChild(br)

    let select2box = buildElement('select', {
        id: select_id,
        class: 'aristotle-select2',
        name: 'items',
        required: '',
        'data-html': 'true',
        'data-autocomplete-light-url': dalurl,
        'data-autocomplete-light-function': 'select2'
    })
    let option = buildElement(
        'option',
        {value: '', selected: ''},
        '---------'
    )
    let label = buildElement(
        'label',
        {for: select_id},
        final_label
    )
    select2box.appendChild(option)
    div.appendChild(label)
    div.appendChild(select2box)
    return div.outerHTML
}

// Add the popover dialog to an editor
function addGlossaryDialog(editor, dialogname, dialogtitle, select_id) {
    editor.dialog.add(dialogname, function(editor) {
        return {
            title: dialogtitle,
            minWidth: 400,
            minHeight: 200,
            contents: [
                {
                    id: 'general',
                    label: 'Settings',
                    elements: [
                        {
                            type: 'html',
                            html: buildGlossaryDialogHtml(
                                'Select metadata item',
                                select_id,
                                '/ac/concept/'
                            )
                        }
                    ]
                }
            ],
            onOk: function() {
                // Get selected glossary item
                let select = document.getElementById(select_id)
                let option = select.options[select.selectedIndex]
                let g_id = option.value
                // If valid options
                if (g_id) {
                    let g_name = option.title

                    // If user has selected some text use that instead
                    let selectedText = editor.getSelection().getSelectedText()
                    if (selectedText) {
                        g_name = selectedText
                    }

                    let linkattrs = {
                        class: 'aristotle-concept-link',
                        href: '/item/' + g_id,
                        'data-aristotle-concept-id': g_id
                    }
                    // Build link
                    let link = buildElement('a', linkattrs, g_name)
                    // Insert link into editor
                    editor.insertHtml(link.outerHTML);
                }
            },
            onLoad: function() {
                // Initialize the select2 box
                initDALWidget($('.cke_dialog_body [data-autocomplete-light-function=select2]').first())
            }
        };
    });
}

export function addPlugins(editor) {
    addGlossaryDialog(editor, 'glossaryListDialog', 'Insert Aristotle item popover', 'id_glossary')
    editor.plugins.add('aristotle_glossary', {
        icons: 'glossary',
        init: function( editor ) {
            editor.addCommand('insertGlossary', new CKEDITOR.dialogCommand('glossaryListDialog'))

            editor.ui.addButton( 'Glossary', {
                label: 'Insert Aristotle item popover',
                command: 'insertGlossary',
            });
        }
    });
}
