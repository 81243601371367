// Logout banner
import moment from 'moment'

import { getCookie } from 'src/lib/cookie.js'

// How often to update banner in milliseconds
const pollInterval = 5000

// Calculate logout time as moment obj
// returns null if calculation can't be done
function getLogoutTime(hard_expiry, activity_age) {
    let logout_time = null
    const access_time = Number(getCookie('access_time'))
    if (access_time) {
        const last_accessed = moment.unix(access_time).utc()
        // Calculate logout time
        logout_time = moment(last_accessed).add(activity_age, 's')
        if (logout_time > hard_expiry) {
            logout_time = hard_expiry
        }
    }
    return logout_time
}

// Update the logout message
function updateLogoutMessage(banner, expiry_options, interval_id) {
    // Seconds under which to show warning
    const logout_time = getLogoutTime(expiry_options.hard_expiry, expiry_options.activity_age)
    // Leave banner if logout time could not be calculated
    if (logout_time) {
        const now = moment()
        // Number of milliseconds until logout (negative if after logout)
        const diff = logout_time.diff(now)

        banner.hidden = (diff > expiry_options.until_logout_warning * 1000)
        if (!banner.hidden) {
            const message_span = banner.querySelector('span.message')
            if (diff > 0) {
                // If there is still time before logout
                const until_str = moment.duration(diff).humanize()
                const at_limit = logout_time.isSame(expiry_options.hard_expiry)
                if (at_limit) {
                    message_span.textContent = 'You will be logged out in ' + until_str
                } else {
                    message_span.textContent = 'You will be logged out due to inactivity in ' + until_str
                }
            } else {
                // If logout already happened
                message_span.textContent = 'You have been logged out'
                if (interval_id !== undefined) {
                    clearInterval(interval_id)
                }
            }
        }
    }
}

export function initLogoutBanner() {
    const banner = document.getElementById('almost-logout-message')
    if (banner) {
        const timestamp = Number(banner.getAttribute('data-hard-expiry-timestamp'))
        // Keep message hidden if this is not valid
        if (timestamp) {
            // Build static expiry options
            const expiry_options = {
                hard_expiry: moment.unix(timestamp).utc(),
                until_logout_warning: Number(banner.getAttribute('data-until-logout-warning')),
                activity_age: Number(banner.getAttribute('data-session-activity-age')),
            }
            // Check once on page load
            updateLogoutMessage(banner, expiry_options)
            // Check repeatedly until logout happens
            const interval_id = window.setInterval(
                () => updateLogoutMessage(banner, expiry_options, interval_id),
                pollInterval,
            )
        }
    }
}
