import { isFormstageElement, buildElement } from 'src/lib/html.js'

// DAL needs the full version
import 'select2/dist/js/select2.full.js'
import 'select2/dist/css/select2.css'


// Initialize all dal elements (urlFunc is optional)
export function initDAL(urlFunc) {
    for (let element of document.querySelectorAll('[data-autocomplete-light-function=select2]')) {
        // If not inside a formstage
        if (!isFormstageElement(element)) {
            initDALWidget($(element), urlFunc)
        }
    }
}

// Build initial select2 element
function buildSelect2item(id, text, url, showId) {
    let strong = document.createElement('strong')
    strong.appendChild(document.createTextNode(text + ' '))
    if (showId && showId != "undefined") {
        // Add elements to strong element
        strong.appendChild(buildElement('small', {}, `(id: ${id}) `))
    }

    // Create and append link if we have a url
    if (url) {
        // Build link element
        let link = buildElement(
            'a', {'class': 'ac_preview_link', 'href': url, 'target': 'preview', 'title': 'Open in a new window',},
        )
        link.appendChild(buildElement('i', {'class': 'fa fa-external-link-square'}))
        // When clicking link open in small window
        link.addEventListener('click', () => {
            window.open(url, 'preview', 'height=600,width=650,resizeable=yes,scrollbars=yes')
        })
        strong.appendChild(link)
    }
    return strong
}

// Get function used to template result
function templateResultHtml(item) {
    if (item.selected_text) {
        var $result = $('<span>')
        $result.html(item.selected_text)
        $result.on('mouseup', '.ac_preview_link', function(e) {
            e.stopPropagation()
        });
        return $result
    }
    return item.text
}

// Template selection used html content from api if available
function templateSelectionHtml(item) {
    // This is a blank entry, return nothing.
    if (!item.id) {
        return item.text
    }
    let url = item.element.getAttribute('data-item-url')
    // This is an item that was loaded with the page.
    let showId = item.element.getAttribute('data-show-id')
    if (!item.selected_text) {
        return buildSelect2item(item.id, item.text, url, showId)
    } else {
        let $result = $('<span>');
        $result.html(item.selected_text);
        let title = $result.find(".title")
        if (title.length > 0) {
            let $title = $(title[0]);
            $title.on('mouseup', '.ac_preview_link', function(e) {
                e.stopPropagation();
            });

            return $title
        } else {
            return $result
        }
    }
}

// Create the select option element using local html creation, rather than getting HTML content from the API
function templateSelectionLocal(item) {
    if (item.id === '') {
        // This is a blank entry, return nothing
        return item.text
    }
    let displayId = item.element.getAttribute('data-display-id')
    let itemUrl = item.element.getAttribute('data-item-url')
    if (!itemUrl) {
        itemUrl = item.url
    }
    if (!displayId) {
        displayId = item.id
    }
    return buildSelect2item(displayId, item.text, itemUrl, displayId)
}

// Initialize a single dal element (urlfunc is optional)
export function initDALWidget(element, urlFunc) {

    function getAjax() {
        if ($(element).attr('data-autocomplete-light-url')) {
            return {
                url: urlFunc === undefined ? $(element).attr('data-autocomplete-light-url') : urlFunc(element),
                dataType: 'json',
                delay: 250,
                cache: true,
                data: function (params) {
                    return {
                        q: params.term,  // search term
                        qs_uuid: element.attr('data-queryset-uuid'),  // cached_queryset name
                        page: params.page,
                        create: element.attr('data-autocomplete-light-create') && !element.attr('data-tags'),
                    }
                },
                processResults: function (data) {
                    if (element.attr('data-tags')) {
                        $.each(data.results, function(index, value) {
                            value.id = value.text
                        })
                    }
                    return data
                },
            }
        }
        return null
    }

    $(element).select2({
        tokenSeparators: element.attr('data-tags') ? [','] : null,
        debug: true,
        containerCssClass: ':all:',
        dropdownCssClass: element.attr('multiple') ? 'aristotle-select2-multiple' : 'aristotle-select2',
        placeholder: element.attr('data-placeholder') || '',
        language: element.attr('data-autocomplete-light-language'),
        minimumInputLength: element.attr('data-minimum-input-length') || 0,
        allowClear: ! $(element).is('[required]'),
        templateResult: templateResultHtml,
        templateSelection: templateSelectionHtml,
        ajax: getAjax(),
        tags: Boolean(element.attr('data-tags')),
    })
}

// Initialize a custom select2 box using templating functions from dal
// This is used in vue components to make boxes that look and behave like our dal ones
export function initCustomSelect2(element, options) {
    options.templateResult = templateResultHtml
    options.templateSelection = templateSelectionLocal

    $(element).select2(options)
}
